<template>
  <div class="MonitorSettingsGeneral">
        <base-textbox label="URL*"
                      ref="url"
                      placeholder="http://my-awesome-site.com"
                      description="The URL we will monitor. Should start with http:// or https://"
                      :rules="urlRules"
                      v-model="monitorData.url"
                      @input="$emit('update:url', monitorData.url)"
        />

        <base-textbox label="Name"
                      placeholder="My awesome site"
                      description="Optional name which will help you identify your site later"
                      v-model="monitorData.name"
                      @input="$emit('update:name', monitorData.name)" />

        <base-textbox label="Notification Delay in minutes"
                      placeholder="0"
                      description="We will notify you if your site is down for specified number of minutes"
                      rules="numeric"
                      v-model="monitorData.offline_notification_delay"
                      @input="$emit('update:offline_notification_delay', monitorData.offline_notification_delay)"/>

        <base-slider label="Max timeout in seconds"
                     v-model="monitorData.request.timeout"
                     description="Time we should wait till your url respond. Otherwise we will consider the check as failed by timeout."
                     :min="0.5"
                     :max="30"
                     :interval="0.5"
                     @input="$emit('update:request_timeout', monitorData.request.timeout)"
                     tooltip="always" />

        <base-select-tag label="Check frequency"
                         v-model="selectedUptimeCheckFrequency"
                         :options="uptimeCheckFrequencies"
                          @update="$emit('update:uptime_check_frequency', selectedUptimeCheckFrequency)"/>
        <base-checkbox label="Enable SSL check"
                       v-model="monitorData.ssl_check"
                        @input="$emit('update:ssl_check', monitorData.ssl_check)"/>
        <p class="enable-ssl-description">Disable SSL checking to stop receiving SSL alerts.</p>

        <base-checkbox style="margin-top: 15px"
                       label="Enable Redirect Check"
                       v-model="monitorData.redirect_check"
                        @input="$emit('update:redirect_check', monitorData.redirect_check)"/>
        <p class="enable-ssl-description">Enable this option to automatically follow HTTP redirects during requests. Disable it to prevent automatic redirection and keep the original response.</p>
  </div>
</template>

<script>
import { cloneObject } from '@/services/utils.js'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      monitorData: {
        name: '',
        url: '',
        offline_notification_delay: ''
      },

      uptimeCheckFrequencies: [
        {
          value: 30,
          label: '30 seconds'
        },
        {
          value: 60,
          label: '1 minute'
        },
        {
          value: 300,
          label: '5 minutes'
        },
        {
          value: 600,
          label: '10 minutes'
        },
        {
          value: 900,
          label: '15 minutes'
        },
        {
          value: 1800,
          label: '30 minutes'
        },
        {
          value: 3600,
          label: '1 hour'
        },
        {
          value: 86400,
          label: '24 hours'
        }
      ],

      selectedUptimeCheckFrequency: null,
      saving: false,
      savingError: false
    }
  },

  created () {
    this.checkUserPlanMonitorsFrequency()

    this.selectedUptimeCheckFrequency = this.uptimeCheckFrequencies.find(
      frequency => frequency.value === this.monitor.uptime_check_frequency
    )
    this.monitorData = cloneObject(this.monitor)
  },

  methods: {
    checkUserPlanMonitorsFrequency () {
      const index = this.uptimeCheckFrequencies.findIndex(el => el.value === this.user.subscription_plan.check_interval)
      this.uptimeCheckFrequencies.splice(0, index)
    }
  },

  computed: {
    urlRules () {
      if (this.monitor.request.type !== 'icmp' && this.monitor.request.type !== 'tcp') {
        return 'required|urlWithProtocol'
      }
      return 'required'
    },

    ...mapState('authentication', ['user'])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorSettingsGeneral {
    max-width: 550px;

    .toolbar {
      position: sticky;
      bottom:0;
      background: rgba(255, 255, 255, 0.9);
      padding: 15px 0;
      backdrop-filter: blur(3px);
    }
  }
  .enable-ssl-description {
    margin-top: -17px;
    font-size: 12px;
    opacity: .6;
    margin-left: 28px;
    line-height: 17px;
  }
</style>
